import React, { useEffect, useState } from "react"

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"

export default function ToTopButton() {
  const [isVisible, setVisible] = useState(true)
  const showButton = () => {
    window.scrollY > 700 ? setVisible(false) : setVisible(true)
  }
  useEffect(() => {
    window.addEventListener("scroll", showButton)
    return () => {
      window.removeEventListener("scroll", showButton)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <button
      className={`bg-gray-400 hover:bg-primary text-white duration-300 rounded-full pointer-events-auto ${
        isVisible && "opacity-0 duration-300 pointer-events-none"
      }`}
      onClick={scrollToTop}
    >
      <ArrowDropUpIcon color="inherit" fontSize="large" />
    </button>
  )
}
