import React, { useEffect, useState } from "react"
import { PopupButton } from "react-calendly"

export default function CalendlyButton() {
  const [isVisible, setVisible] = useState(true)
  const showButton = () => {
    window.scrollY > 500 ? setVisible(false) : setVisible(true)
  }
  useEffect(() => {
    window.addEventListener("scroll", showButton)
    return () => {
      window.removeEventListener("scroll", showButton)
    }
  }, [])

  return (
    <PopupButton
      text="Book a Free Consultation"
      url="https://calendly.com/socialmindr/30minconsultation"
      className={`pointer-events-auto py-2 px-4 bg-primary duration-300 hover:bg-primary-lighter text-white text-xs rounded-md border border-white ${
        isVisible && "opacity-0 duration-300 pointer-events-none"
      }`}
    />
  )
}
