import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"

export default function Footer() {
  return (
    <footer className="bg-dark py-8 md:pt-16 px-4 sm:px-7 md:px-8 lg:px-5vw text-white uppercase text-xs">
      <div className="relative flex flex-col md:flex-row justify-center md:justify-between md:items-center">
        <div className="w-1/3 h-12 md:h-6 self-center">
          <AniLink to="/" cover direction="up" bg="#ffffff">
            <div className="text-white opacity-60 hover:opacity-100 duration-300 w-full h-full flex flex-row justify-center md:justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 574.54 93.93"
                fill="currentColor"
              >
                <path
                  d="M0,34.31a47,47,0,0,1,2.07-9.23C7.46,11.15,17.45,2.79,32.23.31c3.49-.58,3.5-.47,3.5,3.06,0,6.74,0,13.47,0,20.21,0,1-.3,1.41-1.18,1.59-.19,0-.37.1-.56.15-.69.17-1.27,1.1-2,.69A11.75,11.75,0,0,1,30,24c-.41-.41,0-.81.08-1.2a6.14,6.14,0,1,0-7.16,4.07,4.3,4.3,0,0,0,2.88-.08c1.12-.54,1.71,0,2.47.68,1,.92,1.32,1.6.6,3a8.61,8.61,0,0,0,.25,8.15c.37.7.45,1.13-.19,1.73-1.68,1.56-3.28,3.21-4.88,4.86a1.26,1.26,0,0,1-1.64.31,6.8,6.8,0,0,0-7.81,1.56,6.77,6.77,0,0,0-1.64,7.68,7.06,7.06,0,0,0,6.51,4.66c4.89.39,8.74-4.92,7-9.64A1.84,1.84,0,0,1,27,47.42c1.58-1.45,3.07-3,4.56-4.55A1,1,0,0,1,33,42.62a9.17,9.17,0,0,0,9.2,0c.58-.32.93-.35,1.35.18a12.48,12.48,0,0,0,1,1c1.14,1.14,2.2,2,1.7,4.15-.65,2.81,2.34,5.46,5.38,5.37a5.38,5.38,0,0,0,5.11-5.64c-.35-3.18-3.34-5.56-6.28-4.8a1.94,1.94,0,0,1-2.35-.75,1.89,1.89,0,0,0-.2-.2c-1.69-1.31-2.54-2.55-1.28-4.91s.72-4.89-.55-7.17A1.28,1.28,0,0,1,46.44,28a5.43,5.43,0,0,0,.83-.83c1.19-1.39,2.22-2.23,4.46-1.42,3.2,1.16,6.76-.85,8.24-3.94a7.42,7.42,0,0,0-11.12-9.17,7.31,7.31,0,0,0-2.43,8.74c.37.84.29,1.32-.37,1.84a10.84,10.84,0,0,0-.84.82C43,26.24,43,26.2,40,25c-.82-.34-.6-.91-.6-1.43,0-6.54,0-13.08,0-19.62v-2c0-2,0-2,2-1.79A37.83,37.83,0,0,1,73.8,27.25c1.46,5.3,1.49,10.69,1.41,16.11a6.32,6.32,0,0,0,.86,3.31c3,5.19,5.92,10.42,8.85,15.64,2.21,3.91,0,7.76-4.52,7.78-1.27,0-2.54.08-3.8,0-1.09-.08-1.41.25-1.4,1.36.07,5.67,0,11.33,0,17a5.08,5.08,0,0,1-4.33,5.41,5.35,5.35,0,0,1-1.6.08q-17.55,0-35.12,0C27,94.2,20,88,18.68,80.35a50.22,50.22,0,0,1-.3-8.31,2.93,2.93,0,0,0-1.51-2.73A37.68,37.68,0,0,1,.74,46.8a49.55,49.55,0,0,1-.74-6Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M123.85,65.87a45.77,45.77,0,0,0,11.49,3.44,17.63,17.63,0,0,0,7.41-.11c1.59-.43,2.76-1.26,3-3a3.51,3.51,0,0,0-1.85-3.55,60.67,60.67,0,0,0-8.9-4.37c-2.84-1.28-5.71-2.53-8-4.79a12.29,12.29,0,0,1,2.88-19.24,24,24,0,0,1,10.75-2.34,38.29,38.29,0,0,1,15.8,3c.88.36,1,.72.61,1.6-1,2.16-1.88,4.38-2.74,6.6-.37,1-.75,1.21-1.79.77-3.68-1.58-7.42-3-11.53-2.84a11,11,0,0,0-2.74.37c-2.48.71-3,3-1.14,4.83a11.54,11.54,0,0,0,3,1.81c3.71,1.83,7.65,3.13,11.3,5.12,7.27,3.94,7.69,11.92,5.25,17.49-1.67,3.82-4.94,5.92-8.81,6.84a40.13,40.13,0,0,1-22.89-1,1.45,1.45,0,0,1-1.15-1.67C123.89,72,123.85,69,123.85,65.87Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M250.68,65c0,3.22,0,6.34,0,9.46a1.49,1.49,0,0,1-.94,1.46,22.25,22.25,0,0,1-10.5,2.73,30.51,30.51,0,0,1-11.12-1.24c-6.2-2.08-9.75-6.59-11.26-12.75a35.9,35.9,0,0,1,0-18c2.22-8.42,8.09-13.4,16.75-14.49a33.59,33.59,0,0,1,17.06,2.13c1.08.44,1.37.89.87,2-1,2.23-1.8,4.52-2.61,6.82-.36,1-.66,1.42-1.89.91a22.38,22.38,0,0,0-9.46-2.19c-3.68.13-6.43,1.75-7.75,5.2a22.94,22.94,0,0,0-.2,16c1.27,3.65,4.1,5.37,8,5.48a21.38,21.38,0,0,0,11.57-3A2.43,2.43,0,0,1,250.68,65Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M348.44,46.62c0,9.91,0,19.82,0,29.72,0,1.32-.36,1.64-1.63,1.6-3.08-.09-6.15-.09-9.23,0-1.26,0-1.64-.27-1.63-1.6,0-15.91,0-31.82,0-47.73,0-4.05,0-8.1,0-12.15,0-1.13.34-1.4,1.41-1.38,3.17.06,6.34.07,9.51,0,1.2,0,1.58.29,1.57,1.53C348.42,26.61,348.44,36.61,348.44,46.62Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M366.38,40.05c5.54-9.58,22.51-9,26,.63.23,0,.33-.14.44-.31,3.82-5.82,9.37-7.72,16-7.06,5.46.53,9.38,3.07,11,8.5a21.35,21.35,0,0,1,.92,6.2c0,9.51,0,19,0,28.55,0,1.11-.32,1.4-1.4,1.38-2.68-.06-2.68,0-2.68-2.69V48.31a15.22,15.22,0,0,0-1.52-7.08,7.34,7.34,0,0,0-3.14-3.17c-4.53-2.43-11.88-1.25-15.3,2.53-2.35,2.6-3.1,5.87-3.15,9.22-.11,8.78-.09,17.57,0,26.35,0,1.4-.33,1.85-1.76,1.79-2.48-.12-2.49,0-2.48-2.47V47.69a15.32,15.32,0,0,0-.76-5,8.49,8.49,0,0,0-3-4.08c-3.42-2.61-12.82-2.52-16.36,2.27a16.93,16.93,0,0,0-2.93,8.45,100.25,100.25,0,0,0-.22,10.82q-.06,8.21,0,16.4c0,1-.19,1.45-1.35,1.43-2.78-.07-2.78,0-2.78-2.76q0-19.84,0-39.68c0-.62-.4-1.7.9-1.46,1,.18,2.56-1,2.71,1.23C365.8,36.77,366.11,38.25,366.38,40.05Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M273.33,55.25c0,7.08,0,14.16,0,21.24,0,1.16-.32,1.47-1.47,1.44q-4.75-.11-9.51,0c-1.13,0-1.38-.33-1.37-1.4q0-21.16,0-42.32c0-1,.15-1.47,1.33-1.44,3.27.09,6.54.07,9.81,0,1,0,1.21.31,1.2,1.24C273.32,41.1,273.33,48.18,273.33,55.25Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M457.89,40c5.45-7,12.77-7.67,20.54-6.18,5.57,1.07,9,5.71,9.58,12,.41,4.53.18,9.07.21,13.6,0,5.67,0,11.33,0,17,0,1.2-.29,1.6-1.53,1.56-2.55-.08-2.55,0-2.55-2.52q0-13,0-25.92a25.39,25.39,0,0,0-.31-4.08c-.77-4.88-3.5-7.68-8.41-8.36a21.19,21.19,0,0,0-8.42.49c-4.84,1.28-7.55,4.54-8.65,9.3a33,33,0,0,0-.73,7.41q-.06,11.06,0,22.11c0,1.17-.24,1.62-1.51,1.57-2.49-.09-2.49,0-2.49-2.44q0-20,0-40c0-.71-.32-1.73,1-1.5,1,.17,2.57-.78,2.59,1.43A35.41,35.41,0,0,0,457.89,40Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M555.3,42.08c.63-.95,1-1.5,1.37-2.08,3.29-5.48,8.25-7.41,14.42-6.75l1.16.14c2.56.29,2.52.28,2,2.82-.2,1-.4,1.46-1.67,1.18a14.32,14.32,0,0,0-7.52,0c-5,1.57-7.37,5.45-8.81,10.11a20.07,20.07,0,0,0-.74,5.93c0,7.65,0,15.31,0,23,0,1.25-.38,1.56-1.57,1.52-2.61-.08-2.61,0-2.61-2.63V35.5c0-.74-.25-1.73,1.07-1.47,1,.2,2.58-.85,2.57,1.37S555.19,39.58,555.3,42.08Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M439.07,55.92c0,6.88,0,13.76,0,20.64,0,1.11-.29,1.4-1.38,1.38-2.75-.06-2.75,0-2.75-2.77,0-13.23,0-26.45,0-39.68,0-1.24.33-1.54,1.53-1.5,2.6.09,2.6,0,2.6,2.6Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M267.12,27.23c-5,0-7.45-3-6.44-7.88A4.73,4.73,0,0,1,264,15.59a10.43,10.43,0,0,1,5.91-.16c3.05.78,4.39,3.13,4,6.83-.32,2.93-2.44,4.76-5.73,5C267.8,27.25,267.46,27.23,267.12,27.23Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M43.47,34.17a5.85,5.85,0,0,1-5.85,6,5.92,5.92,0,0,1-.15-11.83A5.82,5.82,0,0,1,43.47,34.17Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M53.26,14.86a3.72,3.72,0,0,1,3.81,3.61,3.85,3.85,0,0,1-3.79,3.83,3.77,3.77,0,0,1-3.78-3.8A3.67,3.67,0,0,1,53.26,14.86Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M19.7,48.66a3.52,3.52,0,1,1-.18,7,3.52,3.52,0,0,1,.18-7Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M439.65,22a4.7,4.7,0,0,1-.52,2.55,2.31,2.31,0,0,1-2.41,1.3,2.15,2.15,0,0,1-2-1.67,7.52,7.52,0,0,1-.09-4.18,2.3,2.3,0,0,1,2.21-2,2.44,2.44,0,0,1,2.53,1.82A5.47,5.47,0,0,1,439.65,22Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M24.26,23.3a2.43,2.43,0,1,1-.08-4.85,2.35,2.35,0,0,1,2.47,2.32A2.32,2.32,0,0,1,24.26,23.3Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M53,48.07a1.46,1.46,0,0,1-1.56,1.59,1.43,1.43,0,0,1-1.62-1.48A1.59,1.59,0,1,1,53,48.07Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M323.42,68.94c0-7.71.14-15.42-.1-23.13-.19-6.08-3.24-10.54-9.13-12.46-9-2.92-17.68-1.65-26.13,2.21-.75.34-.49.77-.23,1.28C288.91,39,290,41.19,291,43.4c.35.77.68.85,1.41.52a35.81,35.81,0,0,1,9.35-2.76c6.13-.93,9.26,1.4,9.43,7,0,1.34-.35,1.74-1.68,1.75a72,72,0,0,0-13.12.86c-3.33.63-6.56,1.57-9.2,3.84-3.8,3.29-4.33,7.68-3.86,12.3.43,4.3,2.26,7.84,6.16,10A13.72,13.72,0,0,0,296,78.65c5,.19,9.76-.35,13.6-4,.93-.91,1.91-1.77,2.94-2.73.64,1.67,1.3,3.34,1.91,5a1.33,1.33,0,0,0,1.51,1c2.15-.06,4.3-.05,6.44,0,.83,0,1.1-.24,1.09-1.08C323.39,74.22,323.42,71.58,323.42,68.94Zm-23.87.44A4.1,4.1,0,0,1,296,65.74c-.4-2.6.14-4.87,2.34-6.5s4.69-1.81,7.22-2c.77-.07,1.56,0,2.34,0,3.29-.17,3.22-.17,3.27,3.12C311.3,67,306.17,70.58,299.55,69.38Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M207,45.64a18.92,18.92,0,0,0-14-13,26.65,26.65,0,0,0-13.45.16,18.28,18.28,0,0,0-12.84,11.35,28.33,28.33,0,0,0-1.89,11,32.78,32.78,0,0,0,.43,5.68c1.31,7.05,4.69,12.64,11.37,15.81a24.2,24.2,0,0,0,13.14,1.86c8.23-.85,14.18-4.92,17.07-12.81A29.87,29.87,0,0,0,207,45.64ZM194.5,63.8c-1.38,3.41-4,4.87-7.54,4.94s-6.49-1.13-8.06-4.63c-1.44-3.19-1.61-6.59-1.52-10a20,20,0,0,1,1.28-7.16c1.51-3.63,4.1-5.14,8.66-5a7.62,7.62,0,0,1,7.39,5.38,26.33,26.33,0,0,1,1.22,8A25.74,25.74,0,0,1,194.5,63.8Z"
                  transform="translate(0 0)"
                />
                <path
                  d="M536.78,17.94c0-3,0-3-3-2.86-.74,0-1,.23-1,1,.08,8.15-.13,16.3.16,24.52-.1,0-.16,0-.19-.05a6,6,0,0,1-.48-.54c-3.33-4.94-8.16-6.86-13.93-6.87-8.72,0-14.66,4.3-17.16,12.65a39.18,39.18,0,0,0-.86,17.66A18.06,18.06,0,0,0,528,76.14,20.41,20.41,0,0,0,533,71.07c.33,2.12.67,4,.91,5.84.08.71.28,1,1,1,1.35.12,1.82-.22,1.82-1.71C536.76,56.8,536.78,37.37,536.78,17.94Zm-4.87,47c-1.35,6.15-4.89,9.32-11.17,10-3.79.41-7.51,0-10.75-2.29-3.61-2.6-4.86-6.46-5.56-10.57a26.48,26.48,0,0,1-.37-5.43,31.66,31.66,0,0,1,1.22-9.79c2.64-8.5,8.52-10.72,16.12-9.79,5.86.72,9.25,4.24,10.45,9.89A42.14,42.14,0,0,1,531.91,65Z"
                  transform="translate(0 0)"
                />
              </svg>
            </div>
          </AniLink>
        </div>
        <ul className="md:flex md:flex-row">
          <li>
            <AniLink
              cover
              direction="up"
              bg="#ffffff"
              to="/"
              className="opacity-60 hover:opacity-100 duration-300"
              activeClassName="opacity-100"
            >
              Home
            </AniLink>
          </li>
          <li className="py-1 md:py-0 md:mx-4">
            <AniLink
              cover
              direction="up"
              bg="#ffffff"
              to="/what-we-do"
              className="opacity-60 hover:opacity-100 duration-300"
              activeClassName="opacity-100"
            >
              What we do
            </AniLink>
          </li>
          <li>
            <AniLink
              cover
              direction="up"
              bg="#ffffff"
              to="/contact-us"
              className="opacity-60 hover:opacity-100 duration-300"
              activeClassName="opacity-100"
            >
              Contact us
            </AniLink>
          </li>
          <li className="pt-1 md:pt-0 md:ml-4">
            <AniLink
              cover
              direction="up"
              bg="#ffffff"
              to="/articles/"
              className="opacity-60 hover:opacity-100 duration-300"
              activeClassName="opacity-100"
            >
              Articles
            </AniLink>
          </li>
        </ul>
      </div>
      <p className="opacity-60 pt-3 md:pt-6 md:text-center">
        &copy; 2021 SocialMindr. All rights reserved.
      </p>
    </footer>
  )
}
