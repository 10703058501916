import React, { useState } from "react"
import { RemoveScroll } from "react-remove-scroll"
import CalendlyButton from "./CalendlyButton"

import Footer from "./Footer"
import Navigation from "./Navigation"
import ToTopButton from "./ToTopButton"

export default function Layout(props) {
  const [isOpen, setOpen] = useState(false)

  return (
    <RemoveScroll enabled={isOpen}>
      <div className="flex flex-col relative">
        <header className="fixed inset-0 pointer-events-none z-40">
          <Navigation isOpen={isOpen} setOpen={setOpen} />
        </header>
        <div>{props.children}</div>
        <Footer />
        <div className="fixed inset-0 z-30 pointer-events-none">
          <div className="absolute bottom-0 right-0 p-4 grid grid-flow-col-dense gap-4">
            <ToTopButton />
            {props.calendly && <CalendlyButton />}
          </div>
        </div>
      </div>
    </RemoveScroll>
  )
}
